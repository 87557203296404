import * as React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import {ExternalLinkButton} from '../components/components';
import {COLORS} from '../style/my-theme';
import SponsorsSectionNew from '../components/sections/sponsors-section-new';
import SponsorsSection from '../components/sections/sponsors-section-old';
import CONTENT from '../components/content';

const StyledSponsorsContainer = styled.div.attrs({
  className: 'sponsors-container'
})`
  .header-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-image: url('/images/footer-stickers.jpeg');
    font-family: 'Montserrat', sans-serif;
    color: ${COLORS.white};
    .section-description {
      line-height: 2rem;
      text-align: center;
    }
  }
`;

const TicketsPage = () => {
  return (
    <Layout title="Sponsors - Armada JS 2023">
      <StyledSponsorsContainer>
        <div className="header-wrapper">
          <h2>
            Armada JS will attract more than 500 attendees from 100+ companies and offer 16+ hours
            of amazing JavaScript talks.
          </h2>
          <h4>
            You have a unique opportunity to promote your company, product, or service, to a group
            of passionate, ambitious, and dedicated Javascript Developers. Our Demographics include
            Frontend Javascript Developers, Fullstack Developers, CTO's, Tech Leads, Web Developers
            and more. You have the chance to increase your team's knowledge, hire the best talent,
            increase your brand awareness, show your investment in the sector, and reinforce your
            teams!
          </h4>
          <div className="section-description">
            <h2>WANT TO JOIN AS A SPONSOR?</h2>
            <ExternalLinkButton
              label="CONTACT US!"
              url="mailto:contact@armada-js.com"
            ></ExternalLinkButton>
          </div>
        </div>
        {/* <SponsorsSectionNew /> */}
        <SponsorsSection sponsors={CONTENT.SPONSORS_OLD} />
      </StyledSponsorsContainer>
    </Layout>
  );
};
export default TicketsPage;
